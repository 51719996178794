import React from 'react';
import './BarraAjuda.css';


function Barra() {

    // Função para manipular o clique no botão
    const handleButtonClick = () => {
        // Redirecionar para a URL desejada
        window.location.href = 'https://wa.me//556934451022?text= Oi, vim pelo site, preciso de ajuda!';
    };

    return (
        <div className="section-barra-boxes">
            <p className="section-title">Quero ajuda para escolher meu plano
            <button className="btn" onClick={handleButtonClick}>Falar com Atendente</button>
            </p>
        </div>
    );
}

export default Barra;