import React from "react";
import "./Footer.css";

// Importe a imagem
import logogiga from "./../assets/brand/logo-wm-branco-horizontal.webp"; // Atualize o caminho conforme a estrutura do seu projeto

function Footer() {
  return (
    <div className="section-footer-body">
      <div className="section-footer-boxes">
        <div className="left-footer-column">
          <img
            src={logogiga}
            alt="Imagem com Logo da UNI e as empresas participantes do grupo"
            width="250"
          />
        </div>

        <div className="middle-footer-column">
          <p className="section-subtitle">
            Uni Servicos de Tecnologia da Informacao LTDA <br></br> CNPJ:
            49.232.014/0001-20
          </p>
          <p className="section-subtitle">
            WM Provedor de Internet ® – 2024 <br></br> Todos os direitos
            reservados{" "}
          </p>
        </div>

        <div className="right-footer-column">
          <ul class="example-2">
            <li class="icon-content">
              {" "}
              {/* INICIO ICONE DO WHATASPP */}
              <a
                href="https://wa.me//556934451022?text=Oi, vim pelo site, preciso de ajuda!"
                aria-label="whatsapp"
                data-social="whatsapp"
              >
                <div class="filled"></div>
                <svg
                  width="64px"
                  height="64px"
                  viewBox="-1.2 -1.2 26.40 26.40"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  stroke="currentColor"
                  transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
                  stroke-width="0.00024000000000000003"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#CCCCCC"
                    stroke-width="0.24000000000000005"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill-rule="nonzero"
                        d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
              <div class="tooltip">Whatsapp</div>
            </li>{" "}
            {/* FIM ICONE DO WHATASPP */}
            <li class="icon-content">
              {" "}
              {/* INICIO ICONE DO YOUTUBE */}
              <a
                href="https://ixc.wm.souuni.com/central_assinante_web/login"
                aria-label="assinante"
                data-social="assinante"
              >
                <div class="filled"></div>
                <svg
                  width="64px"
                  height="64px"
                  viewBox="0 0 24.00 24.00"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0.00024000000000000003"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke="#CCCCCC"
                    stroke-width="0.576"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path fill="none" d="M0 0h24v24H0z"></path>{" "}
                      <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </a>
              <div class="tooltip">Assinante</div>
            </li>{" "}
            {/* FIM ICONE DO ASSINANTE */}
            <li class="icon-content">
              {" "}
              {/* INICIO ICONE DO INSTAGRAM */}
              <a
                href="https://www.instagram.com/souuni.wm/"
                aria-label="instagram"
                data-social="instagram"
              >
                <div class="filled"></div>
                <svg
                  width="64px"
                  height="64px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill-rule="nonzero"
                        d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
              <div class="tooltip">Instagram</div>
            </li>{" "}
            {/* FIM ICONE DO INSTAGRAM */}
            {/* INICIO ICONE DO FACEBOOK */}
            {/* <li class="icon-content">   
                            <a
                                href="https://www.facebook.com/giganet.ro/?locale=pt_BR"
                                aria-label="facebook"
                                data-social="facebook"
                            >
                                <div class="filled">

                                </div>
                                <svg width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"></path></g></g></svg>
                            </a>
                            <div class="tooltip">Facebook</div>
                        </li>                        */}
            {/* FIM ICONE DO FACEBOOK */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
